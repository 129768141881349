export class AppConfig {
    private static urlLRApi;
    private static urlLRSig;

    public static obterUrlLRApi() {
        return this.urlLRApi;
    }

    public static definirUrlLRApi(url) {
        this.urlLRApi = url;
    }

    public static obterUrlLRSig() {
        return this.urlLRSig;
    }

    public static definirUrlLRSig(url) {
        this.urlLRSig = url;
    }
}